import React from 'react';
import styled from 'styled-components';
import { Container } from 'styled-bootstrap-grid';
import SheardAcardeon from './Sheard-acardeon';
import { colors, device } from '../theme/constants';

const SectionLayout = styled.section`
  position: relative;
  background-color: ${colors.gray.headerBackground};
  border-color: ${colors.gray.headerBackground};
  color: ${colors.gray.headerBackground};
  padding-top: 5rem;
  padding-bottom: 5rem;

  @media ${device.laptop} {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
`;

const WarningContainer = styled.div`
  color: ${colors.insta.btn};
  margin-bottom: 5rem;
  font-weight: 900;
  font-size: 1.5rem;
  text-align: justify;
  font-style: italic;

  @media ${device.laptop} {
    font-size: 2.3rem;
  }
`;

const MedicinesMainSection = (props) => {
  return (
    <SectionLayout>
      <Container>
        <WarningContainer>
          توجه: " اکیدا به شما توصیه میکنیم با پزشک معالج خود در مورد وضعیت خاص
          پزشکی خود و درمان آن صحبت نمایید. اطلاعاتی که در این وب سایت است
          میتواند مفید باشد و آموزشی است، اما یک جایگزین برای توصیه های پزشکی
          نیست ".
        </WarningContainer>
        <SheardAcardeon data={props.medicines}></SheardAcardeon>
      </Container>
    </SectionLayout>
  );
};

export default MedicinesMainSection;
