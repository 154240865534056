import React from 'react';
import Layout from '../components/Layout';
import HeadPanel from '../components/HeadPanel';
import MedicinesMainSection from '../components/Medicines-main-section';
import { useMedicines } from '../hooks/medicines';
import { useFiles } from '../hooks/files';

const MedicinesPage = () => {
  const medicines = useMedicines();
  const { blogPills } = useFiles();
  return (
    <Layout>
      <div>
        <HeadPanel
          simple
          img={blogPills}
          title={'اطلاعات داروهای شیمی درمانی'}
        ></HeadPanel>
        <MedicinesMainSection medicines={medicines} />
      </div>
    </Layout>
  );
};

export default MedicinesPage;
