import { useStaticQuery, graphql } from 'gatsby';

export const useMedicines = () => {
  const { allWordpressWpMedicines } = useStaticQuery(
    graphql`
      {
        allWordpressWpMedicines(
          filter: { status: { eq: "publish" } }
          sort: { fields: date, order: DESC }
        ) {
          nodes {
            date(formatString: "MMMM DD, YYYY")
            content
            excerpt
            slug
            modified
            title
            status
            wordpress_id
          }
        }
      }
    `,
  );
  const posts = allWordpressWpMedicines.nodes;
  return posts;
};
